import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import { Heading, List, ListItem } from "@instructure/ui-elements";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Image from "../../../components/image";
import QuestionHeader from "../../../components/question-header";
import styles from "./reasonable-prudent-parenting-standards.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";

export class ReasonableAndPrudent extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    openItems: []
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(5);
  }

  handleActivate = id => {
    if (this.state.openItems.includes(id)) {
      this.setState({
        openItems: this.state.openItems.filter(x => x !== id)
      });
    } else {
      this.setState({
        openItems: this.state.openItems.concat(id)
      });
    }
  };

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({ hasSubmitted: true });
    } else {
      navigate("/modules/normalcy/reasonable-and-prudent-activity");
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({ hasSubmitted: false });
    } else {
      navigate("/modules/normalcy/normal-childhood-activities");
    }
  };

  renderScenario() {
    return (
      <>
        <div className={styles.scenarioHeader}>
          <p>
            Now that you know the parenting standard, apply it to the following
            scenario. Would you allow Ana to do the activity?
          </p>
        </div>
        <div className={styles.scenarioImage}>
          <Image alt="water park" filename="water-park.jpg" />
        </div>
        <div className={styles.scenarioText}>
          <p>
            You’ve been fostering 5-yr-old Ana for three months. She enjoys
            playing with the neighbor kids next door – they love to run through
            the sprinkler in the backyard. You took Ana to the pool once; she
            wore water-wings and played on the pool steps and was ready to leave
            after an hour. Ana is typical in many ways although sometimes she
            has meltdowns – she had one recently while playing with a group of
            kids at the park.
          </p>
          <p>
            The neighbor just invited Ana to go to the waterslide park with her
            four kids for the afternoon. Although the neighbor will be the only
            adult, her older kids will help out with the younger kids if they
            need it.
          </p>
        </div>
      </>
    );
  }

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - Reasonable & Prudent Parenting Standards"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          {this.state.hasSubmitted ? (
            this.renderScenario()
          ) : (
            <>
              <div className={styles.information}>
                <p className={styles.bold}>
                  Next, let’s explore the reasonable & prudent parenting
                  standard.
                </p>
                <p>
                  The normalcy law allows you, the foster parent, to decide
                  which activities a child in your care can participate in.
                  Because you care for and love the child, you’re most often the
                  best person to judge whether an activity is appropriate.
                </p>
                <p>
                  To help you decide, you should use the{" "}
                  <b>reasonable & prudent parenting standard</b>. The standard
                  has six criteria, known as <b>ARBEFI</b>. They stand for Age,
                  Risk, Behavior, Emotional Growth, Family and Best Interest.
                  Take time now to learn about each one.
                </p>
              </div>
              <div className={styles.instructionPrompt}>
                <QuestionHeader
                  icon={
                    <span
                      className={styles.emoji}
                      role="img"
                      aria-label="Thinking Face"
                    >
                      🤔
                    </span>
                  }
                >
                  Click each letter to read the criteria.
                </QuestionHeader>
              </div>
              <div className={styles.interactiveLetters}>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("A")}
                  >
                    A
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("A")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Age</Heading>
                    </div>
                    <p>
                      Consider the child's:
                      <List>
                        <ListItem>Age</ListItem>
                        <ListItem>Maturity</ListItem>
                        <ListItem>Developmental level</ListItem>
                      </List>
                    </p>
                  </div>
                </div>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("R")}
                  >
                    R
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("R")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Risk</Heading>
                    </div>
                    <p>
                      Assess the various:
                      <List>
                        <ListItem>Potential risk factors</ListItem>
                        <ListItem>Appropriateness of activity</ListItem>
                      </List>
                    </p>
                  </div>
                </div>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("B")}
                  >
                    B
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("B")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Behavior</Heading>
                    </div>
                    <p>
                      Take into account:
                      <List>
                        <ListItem>Child’s behavioral history</ListItem>
                        <ListItem>Ability to participate safely</ListItem>
                      </List>
                    </p>
                  </div>
                </div>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("E")}
                  >
                    E
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("E")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Emotional Growth</Heading>
                    </div>
                    <p>
                      Does the activity encourage the child’s:
                      <List>
                        <ListItem>Emotional growth?</ListItem>
                        <ListItem>Developmental growth?</ListItem>
                      </List>
                    </p>
                  </div>
                </div>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("F")}
                  >
                    F
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("F")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Family</Heading>
                    </div>
                    <p>
                      Does the activity contribute to a family-like living
                      experience?
                    </p>
                  </div>
                </div>
                <div className={styles.criteria}>
                  <Button
                    variant="link"
                    size="large"
                    onClick={() => this.handleActivate("I")}
                  >
                    I
                  </Button>
                  <div
                    style={{
                      visibility: this.state.openItems.includes("I")
                        ? "visible"
                        : "hidden"
                    }}
                    className={styles.criteriaContainer}
                  >
                    <div className={styles.criteriaHeading}>
                      <Heading level="h3">Interest</Heading>
                    </div>
                    <p>Is the activity in the child’s best interest?</p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>

          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, shouldNavigate = false) {
      dispatch(setCurrentStep(stepId));
      if (shouldNavigate) {
        navigate("/modules/normalcy/reasonable-prudent-parenting-standards");
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(ReasonableAndPrudent);
